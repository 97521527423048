<template>
  <div class="white-bg frontend">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
    <div class="page-content">
      <header>
        <b-container>
          <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <router-link
              :to="'/appointments'"
              class="brand-logo"
            >
              <img :src="logoImg">
            </router-link>
            <!-- /Brand logo-->
          </b-row>
        </b-container>
      </header>

      <div class="breadcrumb-block">
        <b-container>
          <b-row>
            <b-col>
              <b-breadcrumb>
                <b-breadcrumb-item>
                  <router-link :to="'/appointments'">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.1003 5.29305L7.85033 1.21435C7.61699 1.03955 7.32533 1.03955 7.15033 1.21435L1.90033 5.29305C1.72533 5.40959 1.66699 5.58439 1.66699 5.75919V12.1686C1.66699 13.1591 2.42533 13.9166 3.41699 13.9166H11.5837C12.5753 13.9166 13.3337 13.1591 13.3337 12.1686V5.75919C13.3337 5.58439 13.2753 5.40959 13.1003 5.29305ZM8.66699 8.08987V12.7512H6.33366V8.08987H8.66699ZM11.5837 12.7512C11.9337 12.7512 12.167 12.5182 12.167 12.1686V6.05052L7.50033 2.43796L2.83366 6.05052V12.1686C2.83366 12.5182 3.06699 12.7512 3.41699 12.7512H5.16699V7.5072C5.16699 7.1576 5.40033 6.92453 5.75033 6.92453H9.25032C9.60032 6.92453 9.83366 7.1576 9.83366 7.5072V12.7512H11.5837Z"
                        fill="white"
                      />
                      <mask
                        id="mask0"
                        mask-type="alpha"
                        maskUnits="userSpaceOnUse"
                        x="1"
                        y="1"
                        width="13"
                        height="13"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.1003 5.29305L7.85033 1.21435C7.61699 1.03955 7.32533 1.03955 7.15033 1.21435L1.90033 5.29305C1.72533 5.40959 1.66699 5.58439 1.66699 5.75919V12.1686C1.66699 13.1591 2.42533 13.9166 3.41699 13.9166H11.5837C12.5753 13.9166 13.3337 13.1591 13.3337 12.1686V5.75919C13.3337 5.58439 13.2753 5.40959 13.1003 5.29305ZM8.66699 8.08987V12.7512H6.33366V8.08987H8.66699ZM11.5837 12.7512C11.9337 12.7512 12.167 12.5182 12.167 12.1686V6.05052L7.50033 2.43796L2.83366 6.05052V12.1686C2.83366 12.5182 3.06699 12.7512 3.41699 12.7512H5.16699V7.5072C5.16699 7.1576 5.40033 6.92453 5.75033 6.92453H9.25032C9.60032 6.92453 9.83366 7.1576 9.83366 7.5072V12.7512H11.5837Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0)">
                        <rect
                          x="0.5"
                          y="0.5"
                          width="14"
                          height="14"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </router-link>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>
                  Events
                </b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="events main-content events-listings">
        <div class="page-shape" />
        <b-container>
          <b-row class="d-none d-md-block">
            <b-col>
              <div class="section_title">
                <h1>Upcoming Events</h1>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-for="(event, index) in events"
              :key="index"
              md="6"
              lg="4"
            >
              <div class="card">
                <div class="card-img">
                  <router-link
                    :to="{ name: 'event-detail-frontend', params: { slug: event.slug }}"
                  >
                    <img
                      :src="event.imageURLs ? (event.imageURLs.length ? event.imageURLs[0] : '') : ''"
                      class="card-img-top"
                    >
                  </router-link>
                </div>
                <div class="card-body">
                  <h3>
                    <router-link :to="{ name: 'event-detail-frontend', params: { slug: event.slug }}">
                      {{ event.name }}<br>{{ event.nameSG }}
                    </router-link>
                  </h3>
                  <ul class="event_info">
                    <li class="event_date">
                      {{ event.dateLabel }}<br>{{ event.dateLabelSG }}
                    </li>
                    <li class="event_location">
                      {{ event.location }} <br>{{ event.locationSG }}
                    </li>
                  </ul>

                  <ul class="tags">
                    <li
                      v-for="(tag, key) in event.tags"
                      :key="key"
                      class="tag tag_1"
                    >
                      {{ tag }}
                    </li>
                  </ul>

                  <p class="line-breaks-textarea d-none d-md-block">
                    {{ event.description }}
                  </p>

                  <ul class="button_list">
                    <li>
                      <router-link
                        :to="{ name: 'event-detail-frontend', params: { slug: event.slug } }"
                        class="btn btn-link"
                      >
                        Read more 阅读更多
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        v-if="event.packages.length"
                        :to="{ name: 'event-detail-frontend', params: { slug: event.slug }, query: { booking: true } }"
                        class="btn btn-link"
                      >
                        Book now 马上预约
                      </router-link>
                      <router-link
                        v-else
                        :to="{ name: 'event-booking-frontend', params: { slug: event.slug || 0 } }"
                        class="btn btn-link"
                      >
                        Book now 马上预约
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row
            v-if="totalEvents > perPage"
            class="mx-2 mb-2"
          >
            <!-- Pagination -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalEvents"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                align="center"
                prev-text="Prev"
                next-text="Next"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                  <span>Prev</span>
                </template>
                <template #next-text>
                  <span>Next</span>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>

          </b-row>
        </b-container>
      </div>

      <footer>
        <b-container>
          <b-row>
            <b-col>
              <p>© {{ new Date().getFullYear() }} Nirvana Memorial Garden Pte. Ltd.</p>
            </b-col>
          </b-row>
        </b-container>
      </footer>
    </div>

  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BContainer, BCol, BPagination, BBreadcrumb, BBreadcrumbItem,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loader from './Loader.vue'

export default {
  components: {
    BRow,
    BContainer,
    BCol,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,

    Loader,
  },
  data() {
    return {
      events: [],
      currentPage: this.$route.query.page || 1,
      totalEvents: 0,
      perPage: 6,
      logoImg: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },

  watch: {
    currentPage() {
      this.refetchData()
    },
  },

  beforeMount() {
    this.$http.get('front-end/events/upcoming-events/all')
      .then(response => {
        this.events = response.data.events || []
        this.totalEvents = response.data.total || 0
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    refetchData() {
      const queryParams = {
        perPage: this.perPage,
        page: this.currentPage,
      }
      this.$http.get('front-end/events/upcoming-events/all', { params: queryParams })
        .then(response => {
          this.events = response.data.events || []
          this.totalEvents = response.data.total || 0

          this.$router.replace({ query: queryParams })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style>

</style>
